export default [
  {
    label: 'Entry: "Website in a Day" Talk',
    value: "109120214"
  },
  {
    label: "Entry: New User",
    value: "110658455"
  },
  {
    label: "Entry: Paid User",
    value: "111321129"
  },
  {
    label: "Entry: Tips & Tricks",
    value: "104538811"
  },
  {
    label: "Feed: Announcements",
    value: "109480070"
  },
  {
    label: "Feed: Newsletter",
    value: "104537716"
  },
  {
    label: "Feed: User Updates",
    value: "109480031"
  },
  {
    label: "Limbo I",
    value: "111321604"
  },
  {
    label: "Limbo II",
    value: "111330353"
  },
  {
    label: "Sequence - Demo Education",
    value: "111321228"
  },
  {
    label: "Source: Event",
    value: "109644182"
  },
  {
    label: "Source: Facebook",
    value: "104744209"
  },
  {
    label: "Source: Instagram",
    value: "104744215"
  },
  {
    label: "Source: Linkedin",
    value: "107851642"
  },
  {
    label: "Source: Signup",
    value: "110032223"
  },
  {
    label: "Source: Twitter",
    value: "104744212"
  },
  {
    label: "Source: Website",
    value: "104744206"
  }
];
