import {camelCase, capitalCase} from "../_snowpack/pkg/change-case.js";
import {faqs, headline, oneLineMark, subhead, createMainFile, createRegion} from "../fields/index.js";
const tiers = ["newbie", "debut", "midlist"];
const turnDots = "ternary('🟢', '🔴')";
const region = (label, fields) => createRegion({
  label: `${label} Region`,
  name: camelCase(`${label} Region`),
  fields
});
const getCollection = async () => {
  return createMainFile({
    label: "Pricing",
    name: "pricing",
    fields: [
      region("Splash", [headline, {...subhead, required: false}]),
      createRegion({
        label: "Tiers",
        name: "tiers",
        fields: tiers.map((tier) => ({
          label: capitalCase(`${tier} Tier`),
          name: tier,
          widget: "object",
          summary: "{{fields.name}}",
          fields: [
            {
              label: "Name",
              name: "name",
              widget: "string",
              required: true
            },
            {
              label: "For who?",
              name: "who",
              widget: "string",
              required: true
            },
            {
              label: "Yearly Cost",
              name: "yearly",
              hint: "Value in cents. E.G. $1 = 100.",
              widget: "number",
              value_type: "int",
              min: 100,
              default: 100,
              step: 100,
              required: true
            },
            {
              ...oneLineMark,
              label: "Blurb",
              name: "blurb",
              required: true
            },
            {
              label: "Expected",
              name: "expected",
              hint: "When should we expect this tier?",
              widget: "string",
              required: false
            }
          ]
        }))
      }),
      {
        label: "Highlights",
        label_singular: "Highlight",
        name: "highlights",
        widget: "list",
        collapsed: true,
        required: true,
        summary: [
          ...tiers.map((tier) => `{{fields.tiers.${tier}.checked | ${turnDots}}}`),
          "{{fields.title | default('[Feature Name]')}}"
        ].join(" ⬩ "),
        fields: [
          {
            label: "Title",
            name: "title",
            widget: "string",
            required: true
          },
          {
            label: "Tiers",
            name: "tiers",
            widget: "object",
            collapsed: false,
            summary: tiers.map((tier) => `{{fields.${tier}.checked | ${turnDots}}}`).join(" ⬩ "),
            fields: tiers.map((tier) => ({
              label: capitalCase(tier),
              name: tier,
              widget: "object",
              collapsed: true,
              summary: `{{fields.checked | ${turnDots}}} {{fields.text}}`,
              fields: [
                {
                  label: "Checked",
                  name: "checked",
                  widget: "boolean",
                  default: false,
                  required: false
                },
                {
                  label: "Text",
                  name: "text",
                  hint: "Overrides highlight title, if present.",
                  widget: "string",
                  required: false
                }
              ]
            }))
          }
        ]
      },
      region("Features", [headline, {...subhead, required: false}]),
      {
        label: "Features",
        label_singular: "Feature",
        name: "features",
        widget: "list",
        collapsed: true,
        required: true,
        types: [
          {
            label: "Feature Group",
            name: "group",
            widget: "object",
            summary: "{{fields.title}}",
            fields: [
              {
                label: "Title",
                name: "title",
                widget: "string",
                required: true
              },
              {...oneLineMark, label: "Blurb", name: "blurbMark"}
            ]
          },
          {
            label: "Feature",
            name: "feature",
            widget: "object",
            summary: [
              ...tiers.map((tier) => `{{fields.tiers.${tier}.checked | ${turnDots}}}`),
              "{{fields.title | default('[Feature Name]')}}"
            ].join(" ⬩ "),
            fields: [
              {
                label: "Title",
                name: "title",
                widget: "string",
                required: true
              },
              {...oneLineMark, label: "Hint", name: "hintMark"},
              {
                label: "Tiers",
                name: "tiers",
                widget: "object",
                collapsed: false,
                summary: tiers.map((tier) => `{{fields.${tier}.checked | ${turnDots}}}`).join(" ⬩ "),
                fields: tiers.map((tier) => ({
                  label: capitalCase(tier),
                  name: tier,
                  widget: "object",
                  collapsed: true,
                  summary: `{{fields.checked | ${turnDots}}} {{fields.text}}`,
                  fields: [
                    {
                      label: "Checked",
                      name: "checked",
                      widget: "boolean",
                      default: false,
                      required: false
                    },
                    {
                      label: "Text",
                      name: "text",
                      hint: "Overrides icon, if present.",
                      widget: "string",
                      required: false
                    }
                  ]
                }))
              }
            ]
          }
        ]
      },
      region("FAQ", [headline, {...subhead, required: false}, faqs])
    ]
  });
};
export default getCollection;
