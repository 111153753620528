import {blurbMark, coreFields, pageTitle, themeColor, ctaMedia, getPreviewPath, createFolder, createCtaFields} from "../fields/index.js";
const getCollection = async () => {
  const signupFields = await createCtaFields();
  return createFolder({
    label: "CTAs",
    label_singular: "CTA",
    name: "cta",
    folder: "content/cta",
    preview_path: getPreviewPath("/blog"),
    slug: "{{title}}",
    fields: [
      ...coreFields,
      {...pageTitle, hint: "Only used internally."},
      themeColor,
      ctaMedia,
      ...signupFields.filter((f) => f.name !== "blurbMark"),
      {...blurbMark, name: "body"}
    ]
  });
};
export default getCollection;
