import {pageSlug, pageTitle, publishDate, seoDescription, seoTitle, callToAction, pageAuthor, audience, postTags, bannerImage, body, coreFields, getPreviewPath, createFolder} from "../fields/index.js";
const getCollection = async () => {
  return createFolder({
    name: "post",
    label: "Blog Posts",
    label_singular: "Blog Post",
    folder: "content/post",
    preview_path: getPreviewPath("/post/{{fields.slug}}"),
    fields: [
      ...coreFields,
      pageTitle,
      pageSlug,
      pageAuthor,
      publishDate,
      seoTitle,
      seoDescription,
      {...audience, required: true},
      {...postTags, required: true},
      {
        ...callToAction,
        label: "Header CTA",
        hint: "Only shown on desktop. Will fallback to default blog header cta.",
        name: "popupCta"
      },
      {
        ...callToAction,
        label: "Footer CTA",
        name: "footerCta"
      },
      bannerImage,
      body
    ]
  });
};
export default getCollection;
