import {createFileFolder, getPreviewPath, parseCollectionFiles} from "../fields/index.js";
import getBizSettings from "./biz.js";
import getEmailSettings from "./email.js";
import getNavSettings from "./nav.js";
import getSeoSettings from "./seo.js";
import getSocialSettings from "./social.js";
import getTaxSettings from "./tax.js";
const getCollection = async () => {
  return createFileFolder({
    label: "Settings",
    name: "settings",
    format: "yaml",
    preview_path: getPreviewPath("/"),
    files: await parseCollectionFiles(getBizSettings(), getSocialSettings(), getSeoSettings(), getNavSettings(), getTaxSettings(), getEmailSettings())
  });
};
export default getCollection;
