import {format} from "../_snowpack/pkg/date-fns.js";
import {fromJS} from "../_snowpack/pkg/immutable.js";
import {isNone, mapObject} from "../_snowpack/link/libs/rambo/dist/index.js";
import {hubApi} from "../libs/hubApi.js";
const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
const getNanoId = (l = 12) => {
  let result = "";
  const charsLen = chars.length;
  for (let i = 0; i < l; i++) {
    result += chars.charAt(Math.floor(Math.random() * charsLen));
  }
  return result;
};
const ensureId = (obj) => {
  return obj.id ? obj : {...obj, id: getNanoId()};
};
const swallowError = (err) => {
  console.error(err);
  return void 0;
};
const maybeMeta = async (obj, key, url, endpoint) => {
  obj[`${key}Meta`] = url ? await hubApi(endpoint, {
    method: "post",
    body: JSON.stringify({url})
  }).catch(swallowError) : void 0;
  if (isNone(obj[`${key}Meta`])) {
    delete obj[`${key}Meta`];
  }
};
const addMediaMeta = async (obj) => {
  return Object.entries(obj).reduce(async (prom, [key, val]) => {
    const acc = await prom;
    acc[key] = val;
    if (key === "image" || key.endsWith("Image")) {
      const src = val[0];
      if (src) {
        if (src.includes("/video/upload/")) {
          await maybeMeta(acc, key, src, "/video");
        } else {
          await maybeMeta(acc, key, src, "/image");
        }
      }
    } else if (val && (key === "embed" || key.endsWith("Embed"))) {
      await maybeMeta(acc, key, val, "/embed");
    }
    return acc;
  }, Promise.resolve({}));
};
export default function getEvent() {
  return {
    name: "preSave",
    async handler({entry}) {
      console.info("preSave:entry", entry.toJS());
      let data = entry.get("data").toJS();
      if (entry.get("collection") === "settings") {
        if (entry.get("path").includes("/tax.yaml")) {
          data = mapObject((terms) => {
            return terms.map(ensureId);
          }, data);
        }
      } else {
        if (!data.id) {
          data.id = getNanoId();
        }
        if (data.builder) {
          data.builder = await Promise.all(data.builder.map(async (block) => {
            block = ensureId(block);
            block = await addMediaMeta(block);
            if (block.items) {
              block.items = await Promise.all(block.items.map(async (item) => {
                item = ensureId(item);
                item = await addMediaMeta(item);
                return item;
              }));
            }
            return block;
          }));
        }
        const today = format(new Date(), "yyyy-MM-dd");
        data.updateDate = today;
        if (!data.createDate) {
          data.createDate = today;
        }
      }
      console.info("preSave:data", data);
      return fromJS(data);
    }
  };
}
