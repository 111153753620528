import * as __SNOWPACK_ENV__ from './_snowpack/env.js';

import {parseCollections} from "./fields/index.js";
import getPageFiles from "./files/index.js";
import getCtaFolder from "./folders/cta.js";
import getFaqFolder from "./folders/faq.js";
import getPageFolder from "./folders/page.js";
import getPersonFolder from "./folders/person.js";
import getPostFolder from "./folders/post.js";
import getPostTagFolder from "./folders/postTag.js";
import getSeriesFolder from "./folders/series.js";
import getSupportFolder from "./folders/support.js";
import getSupportTagFolder from "./folders/supportTag.js";
import getSettingsFiles from "./settings/index.js";
const cnaryCloudName = __SNOWPACK_ENV__.CLOUDINARY_CLOUD_NAME;
const cnaryApiKey = parseInt(__SNOWPACK_ENV__.CLOUDINARY_API_KEY);
const repo = __SNOWPACK_ENV__.GITHUB_DATABASE_REPO;
const siteUrl = __SNOWPACK_ENV__.APP_SITE_URL;
const localUrl = __SNOWPACK_ENV__.APP_LOCAL_URL;
const cmsUrl = localUrl.startsWith("http") ? localUrl : `https://${localUrl}`;
export default async function getConfig() {
  const collections = await parseCollections(getPageFolder(), getPageFiles(), getPostFolder(), getPostTagFolder(), getSeriesFolder(), getPersonFolder(), getFaqFolder(), getSupportFolder(), getSupportTagFolder(), getCtaFolder(), getSettingsFiles());
  return {
    site_url: siteUrl,
    display_url: siteUrl,
    logo_url: `${cmsUrl}/logo/text.svg`,
    show_preview_links: true,
    publish_mode: "editorial_workflow",
    load_config_file: false,
    collections,
    slug: {
      encoding: "unicode",
      clean_accents: true,
      sanitize_replacement: "-"
    },
    media_folder: "media",
    public_folder: "/media",
    media_library: {
      name: "cloudinary",
      output_filename_only: false,
      use_transformations: true,
      use_secure_url: true,
      config: {
        cloud_name: cnaryCloudName,
        api_key: cnaryApiKey,
        max_files: 100,
        multiple: true
      }
    },
    backend: {
      name: "github",
      repo,
      branch: "master",
      base_url: cmsUrl,
      auth_endpoint: "api/auth/request",
      cms_label_prefix: "cms/",
      squash_merges: false,
      commit_messages: {
        create: '✍️ Create {{collection}} "{{slug}}" ⬩ {{author-name}}',
        update: '✍️ Update {{collection}} "{{slug}}" ⬩ {{author-name}}',
        delete: '✍️ Delete {{collection}} "{{slug}}" ⬩ {{author-name}}',
        uploadMedia: '✍️ Upload file "{{path}}" ⬩ {{author-name}}',
        deleteMedia: '✍️ Delete file "{{path}}" ⬩ {{author-name}}',
        openAuthoring: '✍️ Merge "{{message}}" ⬩ {{author-name}}'
      },
      use_graphql: true
    }
  };
}
