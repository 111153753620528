export const altText = {
  label: "Alt. Text",
  name: "alt",
  widget: "string",
  required: false
};
export const bannerImage = {
  label: "Banner",
  name: "bannerImage",
  widget: "image",
  required: false,
  media_library: {
    name: "cloudinary",
    config: {
      folder: {path: "banner"},
      default_transformations: [
        [
          {
            quality: "auto",
            fetch_format: "webp",
            crop: "lfill",
            gravity: "auto:100",
            aspect_ratio: "2:1"
          }
        ]
      ]
    }
  }
};
export const photoImage = {
  label: "Photo",
  name: "photoImage",
  widget: "image",
  required: false,
  media_library: {
    name: "cloudinary",
    config: {
      folder: {path: "photo"},
      default_transformations: [
        [
          {
            quality: "auto",
            fetch_format: "webp",
            crop: "lfill",
            gravity: "auto:100",
            aspect_ratio: "1:1"
          }
        ]
      ]
    }
  }
};
export const coverImage = {
  label: "Cover Image",
  name: "coverImage",
  hint: "Will crop to 6:9 (cover-sized).",
  widget: "image",
  required: false,
  media_library: {
    name: "cloudinary",
    config: {
      folder: {path: "cover"},
      default_transformations: [
        [
          {
            quality: "auto",
            fetch_format: "webp",
            crop: "lfill",
            gravity: "auto:100",
            aspect_ratio: "6:9"
          }
        ]
      ]
    }
  }
};
export const vectorImage = {
  label: "Vector Image",
  name: "vectorImage",
  hint: "Only SVG allowed.",
  widget: "image",
  required: false,
  media_library: {
    name: "cloudinary",
    config: {
      folder: {path: "vector"},
      default_transformations: [
        [
          {
            quality: "auto",
            fetch_format: "svg"
          }
        ]
      ]
    }
  }
};
export const openGraphImage = {
  label: "OpenGraph Image",
  name: "openGraphImage",
  widget: "image",
  required: false,
  media_library: {
    name: "cloudinary",
    config: {
      folder: {path: "social media"},
      default_transformations: [
        [
          {
            quality: "auto",
            fetch_format: "webp",
            crop: "lfill",
            gravity: "auto:100",
            width: "1200",
            height: "630"
          }
        ]
      ]
    }
  }
};
