import {pageTitle, seoDescription, seoTitle, blurbMark, pageSlug, coreFields, getPreviewPath, createFolder} from "../fields/index.js";
const getCollection = async () => {
  return createFolder({
    name: "postTag",
    label: "Blog Tags",
    label_singular: "Tag",
    folder: "content/postTag",
    preview_path: getPreviewPath("/blog/tag/{{fields.slug}}"),
    fields: [
      ...coreFields,
      pageTitle,
      pageSlug,
      seoTitle,
      seoDescription,
      {
        ...blurbMark,
        name: "body",
        hint: "Show at the top of tag page. Short and sweet."
      }
    ]
  });
};
export default getCollection;
