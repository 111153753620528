import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import mailerliteGroups from "../libs/mailerliteGroups.js";
export const mlGroups = {
  label: "Mailerlite Groups",
  name: "mailerliteGroups",
  widget: "select",
  options: mailerliteGroups,
  default: [__SNOWPACK_ENV__.MAILERLITE_GROUP_DEFAULT],
  required: true,
  multiple: true,
  min: 1,
  max: 5
};
export const pageAuthor = {
  label: "Author",
  name: "author",
  widget: "relation",
  collection: "person",
  value_field: "id",
  search_fields: ["title", "body"],
  display_fields: ["title"],
  options_length: 10,
  required: false
};
export const audience = {
  label: "Audience",
  name: "audience",
  widget: "relation",
  hint: "What should we (auto-)share with whom?",
  collection: "settings",
  file: "tax",
  value_field: "audience.*.id",
  search_fields: ["audience.*.title"],
  display_fields: ["audience.*.title"],
  required: false,
  options_length: 100
};
export const postTags = {
  label: "Tags",
  name: "tags",
  widget: "relation",
  collection: "postTag",
  value_field: "id",
  search_fields: ["title", "body"],
  display_fields: ["title"],
  required: false,
  multiple: true,
  options_length: 100,
  max: 3
};
export const supportTags = {
  label: "Tags",
  name: "tags",
  hint: "Prefer only one tag, unless multiple are necessary.",
  widget: "relation",
  collection: "supportTag",
  value_field: "id",
  search_fields: ["title", "body"],
  display_fields: ["title"],
  required: true,
  options_length: 100,
  multiple: true,
  min: 1,
  max: 3
};
export const faqs = {
  label: "FAQs",
  hint: "Which FAQs should always show here?",
  name: "faqs",
  widget: "relation",
  collection: "faq",
  value_field: "id",
  search_fields: ["title", "body"],
  display_fields: ["title"],
  required: false,
  multiple: true,
  options_length: 100,
  max: 15
};
export const callToAction = {
  label: "Call-to-Action",
  hint: "If blank, will fallback to blog CTA defaults.",
  name: "ctas",
  widget: "relation",
  collection: "cta",
  value_field: "id",
  search_fields: ["title", "headline", "body"],
  display_fields: ["title"],
  multiple: false,
  options_length: 100,
  required: false
};
export const people = {
  label: "People",
  name: "persons",
  widget: "relation",
  collection: "person",
  value_field: "id",
  search_fields: ["title", "body"],
  display_fields: ["title"],
  options_length: 10,
  min: 2
};
