import {getMailerliteGroups} from "../libs/apiReqs.js";
import {localApi} from "../libs/localApi.js";
import {altText, coverImage, vectorImage} from "./image.js";
import {blurbMark, oneLineMark} from "./markdown.js";
import {mlGroups} from "./relation.js";
export const ctaMedia = {
  label: "Media",
  name: "media",
  widget: "object",
  fields: [coverImage, vectorImage, altText]
};
export const headline = {
  ...oneLineMark,
  label: "Headline",
  name: "headline",
  hint: "Max of three line breaks (shift-enter), one paragraph.",
  pattern: ["^.+(\n.+){0,2}$", "Too long"],
  required: true
};
export const subhead = {
  ...oneLineMark,
  label: "Subhead",
  name: "subhead",
  required: true
};
export const buttonText = {
  label: "Button Text",
  name: "buttonText",
  widget: "string",
  pattern: ["^.{0,33}$", "Not so long"],
  required: false
};
export const buttonLink = {
  label: "Button Link",
  name: "buttonLink",
  widget: "string",
  required: false
};
export const createCtaFields = async () => {
  const options = await getMailerliteGroups(localApi);
  return [
    headline,
    blurbMark,
    {...buttonText, required: true},
    {
      ...buttonLink,
      hint: "Enter a link to create a click-only CTA, rather than a signup."
    },
    {
      ...mlGroups,
      ...options ? {options} : {},
      hint: "If using a link button, this field will be ignored."
    }
  ];
};
