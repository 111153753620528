import {openGraphImage, createSettingsFile} from "../fields/index.js";
const getCollection = async () => {
  return createSettingsFile({
    label: "SEO",
    name: "seo",
    fields: [
      {
        label: "Title",
        name: "title",
        widget: "string",
        required: true
      },
      {
        label: "Slogan",
        name: "slogan",
        widget: "string",
        required: true
      },
      {
        label: "Title Separator",
        name: "sep",
        widget: "string",
        pattern: ["^[^\\s]{1,3}$", "Must be short, no spaces"]
      },
      {...openGraphImage, required: true},
      {
        label: "Redirects",
        label_singular: "Redirect",
        name: "redirects",
        hint: "Social media redirects added automatically (e.g. sitearcade.com/facebook).",
        widget: "list",
        summary: "{{source}} → {{destination}}",
        fields: [
          {
            label: "Source",
            name: "source",
            widget: "string",
            required: true
          },
          {
            label: "Destination",
            name: "destination",
            widget: "string",
            required: true
          },
          {
            label: "Permanent",
            name: "permanent",
            widget: "boolean"
          }
        ]
      }
    ]
  });
};
export default getCollection;
