import {isReal} from "../_snowpack/link/libs/rambo/dist/index.js";
import {photoImage} from "./image.js";
import {blurbMark, oneLineMark, body} from "./markdown.js";
import {mlGroups} from "./relation.js";
import {colorKey} from "./settings.js";
export const createBuilder = (opts) => {
  return {
    label: "Builder",
    label_singular: "Block",
    hint: 'Must begin with a "region" block, but must not end with a "region" block, and must not have two "region" blocks in a row.',
    name: "builder",
    required: true,
    ...opts,
    widget: "list",
    summary: "{{fields.title}} {{fields.speakerMark}} {{fields.backdrop | upper}}"
  };
};
export const createTypeField = (opts) => {
  return {
    ...opts,
    widget: "object",
    fields: [
      ...opts.fields ?? [],
      id
    ].filter(isReal)
  };
};
const layout = (opts) => {
  return {
    label: "Layout",
    name: "layout",
    widget: "select",
    required: true,
    ...opts
  };
};
const relation = (nodeOpts, tagOpts) => {
  const result = [{
    required: false,
    multiple: true,
    options_length: 100,
    hint: "Selected content will always be included.",
    widget: "relation",
    value_field: "id",
    search_fields: ["title"],
    display_fields: ["title"],
    ...nodeOpts
  }];
  if (tagOpts) {
    result.push({
      label: "Tags",
      name: "tags",
      hint: "If specified, additional content will be draw from these tags.",
      widget: "relation",
      value_field: "id",
      search_fields: ["title", "body"],
      display_fields: ["title"],
      required: false,
      multiple: true,
      options_length: 100,
      max: 3,
      ...tagOpts
    });
  }
  return result;
};
const getMediaAlign = (defaultAlign = "left", opts = ["above", "below", "left", "right"]) => {
  return {
    label: "Media Align",
    name: "align",
    widget: "select",
    required: true,
    default: defaultAlign,
    options: [
      !opts || opts.includes("left") ? {value: "left", label: "Left"} : null,
      !opts || opts.includes("center") ? {value: "center", label: "Center"} : null,
      !opts || opts.includes("right") ? {value: "right", label: "Right"} : null,
      !opts || opts.includes("above") ? {value: "above", label: "Above"} : null,
      !opts || opts.includes("below") ? {value: "below", label: "Below"} : null
    ].filter(isReal)
  };
};
const id = {
  label: "ID",
  hint: "For anchor links from this page (#id) or elsewhere (/url#id). Auto-generated on save. Caution deleting blocks you link to.",
  name: "id",
  widget: "string",
  required: false
};
const backdrop = {
  ...colorKey,
  label: "Backdrop Color",
  name: "backdrop",
  default: "white"
};
const image = {
  ...photoImage,
  name: "image",
  required: false
};
const eyebrow = {
  label: "Eyebrow",
  name: "eyebrow",
  widget: "string",
  hint: "Small text above title. Use sparingly.",
  pattern: ["^.{0,40}$", "Too long"],
  required: false
};
const title = {
  ...oneLineMark,
  label: "Title",
  name: "title",
  hint: "Max of three line breaks (shift-enter), one paragraph.",
  pattern: ["^.+(\n.+){0,2}$", "Too long"],
  required: true
};
const blurb = {
  ...blurbMark,
  required: false
};
const action = {
  label: "Action",
  name: "action",
  widget: "string",
  pattern: ["^.{0,33}$", "Not so long"],
  required: false
};
const link = {
  label: "Link URL",
  name: "link",
  widget: "string",
  required: false
};
const groups = {
  ...mlGroups,
  hint: "If used, will create signup form instead of CTA button.",
  name: "groups",
  required: false,
  default: void 0,
  min: 0
};
const embed = {
  ...link,
  label: "Embed URL",
  hint: "If used, will replace image with embedded media.",
  name: "embed",
  required: false
};
const textFields = [
  eyebrow,
  title,
  blurb
];
const optionalTextFields = [
  eyebrow,
  {...title, required: false},
  blurb
];
const actionFields = [
  action,
  link,
  groups
];
const mediaFields = [
  image,
  embed,
  layout({
    default: "none",
    options: [
      {value: "none", label: "None"},
      {value: "viewport", label: "Viewport"}
    ]
  })
];
const secondary = {
  label: "Secondary Action",
  hint: "Both required for display.",
  name: "secondary",
  widget: "object",
  required: false,
  collapsed: true,
  summary: "{{fields.action}} ♦️ {{fields.link}}",
  fields: [
    action,
    link
  ]
};
export const regionBlock = {
  label: "Region",
  name: "region",
  widget: "object",
  fields: [
    backdrop,
    layout({
      label: "Divider Layout",
      hint: "Layout dividing previous region. No effect on first region.",
      default: "none",
      options: [
        {value: "none", label: "None"},
        {value: "zigzag", label: "Zig-zag"},
        {value: "waves", label: "Waves"},
        {value: "lines", label: "Lines"}
      ]
    }),
    {
      ...image,
      required: false,
      hint: "Appears overlapping dividing line. No effect on first region."
    },
    getMediaAlign("center", ["left", "center", "right"]),
    {
      label: "Media Lean",
      name: "lean",
      widget: "select",
      required: true,
      default: "center",
      options: [
        {value: "above", label: "Above"},
        {value: "center", label: "Center"},
        {value: "below", label: "Below"}
      ]
    }
  ]
};
export const breakBlock = createTypeField({
  label: "Break",
  name: "break",
  fields: [
    layout({
      default: "candy",
      options: [
        {value: "candy", label: "Candy Cane"}
      ]
    })
  ]
});
export const headlineBlock = createTypeField({
  label: "Headline",
  name: "headline",
  fields: [
    layout({
      default: "none",
      options: [
        {value: "none", label: "Normal"},
        {value: "subhead", label: "Subheading"},
        {value: "bold", label: "Bold Sans-Serif"},
        {value: "plaque", label: "Gold Plaque"},
        {value: "marquee", label: "Lightbulb Marquee"}
      ]
    }),
    ...textFields,
    ...actionFields,
    secondary
  ]
});
export const textMediaBlock = createTypeField({
  label: "Text & Media",
  name: "textMedia",
  fields: [
    ...mediaFields,
    getMediaAlign(),
    ...textFields,
    ...actionFields,
    secondary
  ]
});
export const viewportBlock = createTypeField({
  label: "Viewport",
  name: "viewport",
  fields: [
    {...image, label: "Mobile Image", name: "mobileImage"},
    {...image, label: "Tablet Image", name: "tabletImage"},
    {...image, label: "Desktop Image", name: "desktopImage", required: true},
    getMediaAlign("below", ["above", "below"]),
    ...textFields
  ]
});
export const buttonBlock = createTypeField({
  label: "Big Action",
  name: "action",
  fields: [
    {...action, required: true},
    link,
    groups
  ]
});
export const collectionBlock = createTypeField({
  label: "Item Collection",
  name: "collection",
  fields: [
    layout({
      default: "grid",
      options: [
        {value: "grid", label: "Grid"},
        {value: "list", label: "List"}
      ]
    }),
    {
      label: "Items",
      label_singular: "Item",
      name: "items",
      widget: "list",
      required: true,
      min: 2,
      max: 8,
      summary: "{{fields.title}}",
      fields: [
        {...image, required: false},
        ...mediaFields.slice(1),
        ...optionalTextFields,
        action,
        link
      ]
    }
  ]
});
export const accordionBlock = createTypeField({
  label: "Item Accordion",
  name: "accordion",
  fields: [
    ...textFields,
    {
      label: "Items",
      label_singular: "Item",
      name: "items",
      widget: "list",
      required: true,
      min: 3,
      max: 12,
      summary: "{{fields.title}}",
      fields: [
        {...image, required: true},
        {
          ...title,
          pattern: oneLineMark.pattern,
          hint: oneLineMark.hint
        },
        {...blurb, required: true},
        action,
        link
      ]
    }
  ]
});
export const richTextBlock = createTypeField({
  label: "Rich Text",
  name: "richText",
  fields: [
    {
      widget: "select",
      label: "Options",
      name: "options",
      required: false,
      multiple: true,
      options: [
        {value: "toc", label: "Table of Contents"}
      ]
    },
    {
      ...body,
      label: "Text",
      name: "blurbMark"
    }
  ]
});
export const pullQuoteBlock = createTypeField({
  label: "Pull Quote",
  name: "pullQuote",
  fields: [
    {
      ...blurb,
      label: "Quote",
      buttons: oneLineMark.buttons,
      required: true
    },
    {...oneLineMark, label: "Speaker", name: "speakerMark"},
    {...image, label: "Avatar"}
  ]
});
export const faqsBlock = createTypeField({
  label: "FAQ List",
  name: "faqs",
  fields: [
    ...textFields,
    ...relation({
      label: "FAQs",
      name: "faqs",
      collection: "faq",
      max: 5
    }, {collection: "supportTag"})
  ]
});
export const peopleBlock = createTypeField({
  label: "People List",
  name: "people",
  fields: [
    ...optionalTextFields,
    ...relation({
      label: "People",
      name: "persons",
      collection: "person",
      required: true,
      min: 2
    })
  ]
});
export const postLinksBlock = createTypeField({
  label: "Post Links",
  name: "postLinks",
  fields: [
    ...optionalTextFields,
    layout({
      default: "grid",
      options: [
        {value: "grid", label: "Grid"},
        {value: "list", label: "List"}
      ]
    }),
    {
      label: "Options",
      name: "options",
      widget: "select",
      required: false,
      multiple: true,
      options: [
        {value: "desc", label: "Show Descriptions"}
      ]
    },
    ...relation({
      label: "Posts",
      name: "posts",
      collection: "post",
      max: 6
    }, {collection: "postTag"})
  ]
});
export const supportLinksBlock = createTypeField({
  label: "Support Links",
  name: "supportLinks",
  fields: [
    ...optionalTextFields,
    ...relation({
      label: "Articles",
      name: "supports",
      collection: "support",
      max: 5
    }, {collection: "supportTag"})
  ]
});
export const uniqueBlock = createTypeField({
  label: "Unique Block",
  name: "unique",
  fields: [{
    widget: "select",
    label: "Block",
    name: "block",
    required: true,
    multiple: false,
    options: [
      {value: "contactForm", label: "Contact Form"}
    ]
  }]
});
export const allBlocks = [
  regionBlock,
  breakBlock,
  headlineBlock,
  textMediaBlock,
  buttonBlock,
  collectionBlock,
  accordionBlock,
  richTextBlock,
  pullQuoteBlock,
  faqsBlock,
  peopleBlock,
  postLinksBlock,
  supportLinksBlock,
  viewportBlock,
  uniqueBlock
];
