import {createSettingsFile} from "../fields/index.js";
const getCollection = async () => {
  return createSettingsFile({
    label: "Social Media",
    name: "social",
    fields: [
      {label: "Facebook", name: "facebook"},
      {label: "Twitter", name: "twitter"},
      {label: "Instagram", name: "instagram"},
      {label: "Pinterest", name: "pinterest"},
      {label: "Reddit", name: "reddit"},
      {label: "YouTube", name: "youtube"},
      {label: "LinkedIn", name: "linkedin"}
    ].map(({name, label}) => ({
      label: `${label} Username`,
      name,
      widget: "string",
      required: true
    }))
  });
};
export default getCollection;
