import {b as y} from "./chunk-7SJBQ26G.js";
var a = (t) => Object.keys(t), k = (t, n) => {
  let r = {};
  for (let e in n)
    n.hasOwnProperty(e) && (r[e] = t(n[e], e));
  return r;
}, i = async (t, n) => {
  let r = {};
  for (let e in n)
    n.hasOwnProperty(e) && (r[e] = await t(n[e], e));
  return r;
}, f = (t, n) => Object.keys(n).reduce((r, e) => (r[t(n[e], e, n)] = n[e], r), {}), p = (t, n, r) => {
  let e = y(t, r);
  return n.map((o) => {
    let T = t(o), s = T in e ? e[T] : null;
    return delete e[T], s ? [o, s] : [o, null];
  }).concat(Object.values(e).map((o) => [null, o]));
};
export {a, k as b, i as c, f as d, p as e};
