var o = (n) => typeof n > "u", t = (n) => n === null, s = (n) => !o(n) && !t(n), i = (n) => typeof n == "string", u = (n) => typeof n == "number", p = (n) => typeof n == "boolean", l = (n) => Array.isArray(n), a = (n) => n instanceof Error, r = (n) => {
  if (Object.prototype.toString.call(n) !== "[object Object]")
    return false;
  let e = Object.getPrototypeOf(n);
  return e === null || e === Object.prototype;
}, c = (n) => typeof n == "function", y = (n) => {
  if (!r(n))
    return false;
  for (let e in n)
    if (n.hasOwnProperty(e))
      return false;
  return true;
}, x = (n) => Array.isArray(n) && n.length === 0, b = (n) => {
  try {
    return JSON.stringify(n), true;
  } catch {
    return false;
  }
};
export {o as a, t as b, s as c, i as d, u as e, p as f, l as g, a as h, r as i, c as j, y as k, x as l, b as m};
