export const createLayout = (value) => {
  return {
    filter: {field: "layout", value},
    field: {name: "layout", widget: "hidden", default: value}
  };
};
export const createRegion = (opts) => {
  return {
    ...opts,
    widget: "object",
    collapsed: true
  };
};
