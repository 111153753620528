import {license, linkEx, createRegion, createSettingsFile} from "../fields/index.js";
const getCollection = async () => {
  return createSettingsFile({
    label: "Business & Legal",
    name: "biz",
    fields: [
      {
        label: "Nice Name",
        name: "niceName",
        widget: "string",
        required: true
      },
      {
        label: "Legal Name",
        name: "legalName",
        widget: "string",
        required: true
      },
      {
        label: "Logo Image",
        name: "logoImage",
        widget: "image",
        required: true
      },
      createRegion({
        label: "Licenses",
        name: "licenses",
        fields: [
          {label: "Default", name: "default"},
          {label: "Basic Pages", name: "page"},
          {label: "Blog & Posts", name: "post"},
          {label: "Help & Support", name: "support"}
        ].map((field) => ({...license, ...field}))
      }),
      createRegion({
        label: "Feed Author",
        name: "feedAuthor",
        fields: [
          {
            label: "Name",
            name: "name",
            widget: "string",
            required: true
          },
          {
            label: "Email",
            name: "email",
            widget: "string",
            required: true
          },
          {
            ...linkEx,
            label: "Link",
            name: "link",
            required: true
          }
        ]
      })
    ]
  });
};
export default getCollection;
