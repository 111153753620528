import {createFileFolder, parseCollectionFiles} from "../fields/index.js";
import getBlogMain from "./blog.js";
import getPricingMain from "./pricing.js";
import getSupportMain from "./support.js";
const getCollection = async () => {
  return createFileFolder({
    label: "Main Pages",
    name: "main",
    files: await parseCollectionFiles(getBlogMain(), getPricingMain(), getSupportMain())
  });
};
export default getCollection;
