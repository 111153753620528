import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import fetch from "../_snowpack/pkg/isomorphic-unfetch.js";
export const localApi = async (url, opts) => {
  const res = await fetch(`${__SNOWPACK_ENV__.APP_LOCAL_URL}/api${url}`, {
    mode: "cors",
    ...opts,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      ...opts.headers
    }
  });
  if (!res.ok) {
    throw new Error(res.statusText);
  }
  return res.json();
};
