import {pageTitle, publishDate, blurbMark, supportTags, coreFields, createFolder} from "../fields/index.js";
const getCollection = async () => {
  return createFolder({
    name: "faq",
    label: "Support FAQs",
    label_singular: "FAQ",
    folder: "content/faq",
    slug: "{{slug}}",
    create: true,
    editor: {preview: false},
    fields: [
      ...coreFields,
      {...pageTitle, label: "Question"},
      publishDate,
      supportTags,
      {
        ...blurbMark,
        label: "Answer",
        name: "body",
        required: true
      }
    ]
  });
};
export default getCollection;
