import {pageTitle, seoDescription, seoTitle, blurbMark, publishDate, pageAuthor, pageSlug, audience, postTags, bannerImage, body, callToAction, coreFields, getPreviewPath, createFolder} from "../fields/index.js";
const getCollection = async () => {
  return createFolder({
    name: "series",
    label: "Blog Series",
    label_singular: "Blog Series",
    folder: "content/series",
    preview_path: getPreviewPath("/blog/series/{{fields.slug}}"),
    fields: [
      ...coreFields,
      pageTitle,
      pageSlug,
      pageAuthor,
      publishDate,
      seoTitle,
      seoDescription,
      {...audience, required: true},
      {...postTags, required: true},
      {
        ...callToAction,
        label: "Header CTA",
        hint: "Only shown on desktop. Will fallback to default blog header cta.",
        name: "popupCta"
      },
      bannerImage,
      {
        label: "Posts",
        name: "posts",
        widget: "list",
        collapsed: false,
        minimize_collapsed: true,
        field: {
          label: "Post",
          name: "post",
          widget: "relation",
          collection: "post",
          value_field: "id",
          display_fields: ["title"],
          search_fields: ["title", "body"],
          required: true
        }
      },
      {
        ...blurbMark,
        hint: "Shown with the series list on post pages.",
        required: true
      },
      {
        ...body,
        hint: 'Show at the top of series page. Defaults to "Blurb."',
        required: false
      }
    ]
  });
};
export default getCollection;
