import * as R from "../_snowpack/pkg/ramda.js";
let cacheProm = Promise.resolve(null);
export const getMailerliteGroups = async (api) => {
  const found = await cacheProm;
  if (found?.length) {
    return found;
  }
  const res = await api("/groups", {});
  const list = R.sortBy(R.prop("label"), res?.map((g) => ({label: g.name, value: String(g.id)})) ?? null);
  cacheProm = Promise.resolve(list.length ? list : null);
  return cacheProm;
};
