export const body = {
  label: "Body",
  name: "body",
  widget: "markdown",
  buttons: [
    "bold",
    "italic",
    "code",
    "link",
    "heading-two",
    "heading-three",
    "heading-four",
    "heading-five",
    "heading-six",
    "quote",
    "bulleted-list",
    "numbered-list"
  ],
  required: true
};
export const blurbMark = {
  label: "Blurb",
  name: "blurbMark",
  widget: "markdown",
  minimal: true,
  buttons: [
    "bold",
    "italic",
    "code",
    "link",
    "bulleted-list",
    "numbered-list"
  ],
  editor_components: [],
  required: false
};
export const oneLineMark = {
  ...blurbMark,
  label: "One-Line",
  name: "oneLineMark",
  buttons: ["bold", "italic", "link"],
  pattern: ["^[^\n]*$", "No line breaks"]
};
export const bigBlurbMark = {
  ...blurbMark,
  buttons: [
    "bold",
    "italic",
    "code",
    "link",
    "quote",
    "bulleted-list",
    "numbered-list"
  ]
};
