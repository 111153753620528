import {allBlocks, createBuilder, pageTitle, seoDescription, seoTitle, pagePath, coreFields, getPreviewPath, createFolder} from "../fields/index.js";
const getCollection = async () => {
  return createFolder({
    label: "Pages",
    name: "page",
    label_singular: "Page",
    folder: "content/page",
    preview_path: getPreviewPath("/{{fields.slug}}"),
    preview_preserve_slash: true,
    fields: [
      ...coreFields,
      pageTitle,
      pagePath,
      seoTitle,
      seoDescription,
      createBuilder({
        types: allBlocks
      })
    ]
  });
};
export default getCollection;
