import {id, pageName, pageSlug, createSettingsFile} from "../fields/index.js";
const getCollection = async () => {
  return createSettingsFile({
    label: "Taxonomy",
    name: "tax",
    fields: [
      {
        label: "Audience",
        name: "audience",
        hint: "For sorting blog posts."
      }
    ].map((field) => ({
      ...field,
      label_singular: "Term",
      widget: "list",
      summary: "{{title}}",
      fields: [id, {...pageName, pattern: void 0}, pageSlug]
    }))
  });
};
export default getCollection;
