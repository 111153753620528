import {blurbMark, createSettingsFile} from "../fields/index.js";
const getCollection = async () => {
  return createSettingsFile({
    label: "Email & Templates",
    name: "email",
    fields: [{label: "Contact Form", name: "contact"}].map((field) => ({
      ...field,
      widget: "object",
      required: true,
      fields: [
        {
          label: "Subject Template",
          name: "subjectTpl",
          widget: "string",
          hint: "Will replace user {{name}}, {{email}}, and {{message}}.",
          required: true
        },
        {
          ...blurbMark,
          label: "Body Template",
          name: "bodyTpl",
          hint: "Will replace user {{name}}, {{email}}, and {{message}}.",
          required: true
        }
      ]
    }))
  });
};
export default getCollection;
