import {linkGrid, linkMenu, createCtaFields, createRegion, createSettingsFile} from "../fields/index.js";
const getCollection = async () => {
  return createSettingsFile({
    label: "Navigation",
    name: "nav",
    fields: [
      ...[
        {
          label: "Header Links",
          name: "headerLinks",
          hint: "Appears prominently in site header."
        },
        {
          label: "Menu Links",
          name: "menuLinks",
          hint: "Appears in the hamburger menu."
        }
      ].map((field) => ({...linkMenu, ...field})),
      createRegion({
        label: "Footer Signup",
        name: "footerCta",
        fields: (await createCtaFields()).filter((f) => f.name !== "buttonLink")
      }),
      {
        ...linkGrid,
        label: "Footer Link Grid",
        name: "footerGrid"
      }
    ]
  });
};
export default getCollection;
