import {createDate, updateDate} from "./date.js";
export const id = {
  name: "id",
  widget: "hidden",
  required: false
};
export const pageTitle = {
  label: "Title",
  name: "title",
  hint: "Preferrably longer than 15 characters. Must be below 60.",
  widget: "string",
  required: true,
  pattern: ["^.{1,60}$", "Between 15 and 60 characters"]
};
export const pageName = {
  ...pageTitle,
  label: "Name"
};
export const pageSlug = {
  label: "Slug",
  name: "slug",
  widget: "string",
  pattern: [
    "^[a-z0-9]+(?:-[a-z0-9]+)*$",
    "Must be lowercase and dash-separated"
  ],
  hint: "E.G. this-is-a-valid-slug.",
  required: true
};
export const pagePath = {
  ...pageSlug,
  pattern: [
    "^[a-z0-9]+(?:[-/][a-z0-9]+)*$",
    "Must be lowercase and dash-separated"
  ],
  hint: "E.G. this-is/a-valid-slug.",
  required: true
};
export const coreFields = [
  id,
  createDate,
  updateDate
];
