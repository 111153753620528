import urlRegExp from "../_snowpack/pkg/url-regex.js";
import {oneLineMark} from "./markdown.js";
const absoluteUrlRx = urlRegExp({exact: true, strict: true});
const relativeUrlRx = /^\/[^\s#?]*(?:\?[^\s#]*)?(?:#\S*)?$/u;
const eitherUrlRx = new RegExp(`^(?:${absoluteUrlRx.source.slice(4, -2)}|${relativeUrlRx.source.slice(1, -1)})$`, "i");
export const link = {
  label: "Link",
  name: "link",
  widget: "string",
  pattern: [eitherUrlRx.source, "Not a valid link"],
  required: false
};
export const linkEx = {
  label: "External Link",
  name: "linkEx",
  widget: "string",
  pattern: [absoluteUrlRx.source, "Not a valid external link"],
  required: false
};
export const linkIn = {
  label: "Internal Link",
  name: "linkIn",
  widget: "string",
  pattern: [relativeUrlRx.source, "Not a valid internal link"],
  required: false
};
export const linkText = {
  label: "Text",
  name: "text",
  widget: "string",
  required: true
};
export const linkMenu = {
  label: "Link Menu",
  name: "linkMenu",
  widget: "list",
  fields: [linkText, {...link, required: true}]
};
export const linkGrid = {
  label: "Link Grid",
  label_singular: "Block",
  hint: 'Must begin with "group" block, but must not end with "group" block, and must not have two "group" blocks in a row.',
  name: "linkGrid",
  required: true,
  widget: "list",
  summary: "{{fields.text}}",
  types: [{
    label: "Group",
    name: "group",
    widget: "object",
    fields: [{
      label: "Title",
      name: "text",
      widget: "string",
      required: true
    }, {
      ...oneLineMark,
      label: "Blurb",
      name: "blurbMark",
      required: false
    }]
  }, {
    label: "Link",
    name: "link",
    widget: "object",
    fields: [
      linkText,
      {...link, required: true}
    ]
  }]
};
