import {callToAction, createMainFile} from "../fields/index.js";
const getCollection = async () => {
  return createMainFile({
    label: "Blog",
    name: "blog",
    fields: [
      {
        ...callToAction,
        label: "Header CTA",
        hint: "Will also serve as default post header CTA.",
        name: "popupCta",
        required: true
      },
      {
        ...callToAction,
        label: "Default Post Footer CTA",
        hint: "Serves as default post footer CTA.",
        name: "footerCta",
        required: true
      },
      {
        ...callToAction,
        label: "Sidebar CTA",
        hint: void 0,
        name: "sidebarCta",
        required: true
      }
    ]
  });
};
export default getCollection;
