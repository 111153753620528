import {pageSlug} from "./core.js";
export const themeColor = {
  label: "Theme Color",
  name: "themeColor",
  widget: "select",
  required: true,
  options: [
    {value: "red", label: "Red"},
    {value: "orange", label: "Orange"},
    {value: "yellow", label: "Yellow"},
    {value: "green", label: "Green"},
    {value: "blue", label: "Blue"},
    {value: "purple", label: "Purple"}
  ]
};
export const colorKey = {
  label: "Theme Color",
  name: "themeColor",
  widget: "select",
  required: true,
  options: [
    {value: "red", label: "Red"},
    {value: "orange", label: "Orange"},
    {value: "yellow", label: "Yellow"},
    {value: "green", label: "Green"},
    {value: "blue", label: "Blue"},
    {value: "purple", label: "Purple"},
    {value: "white", label: "White"}
  ]
};
export const license = {
  label: "License",
  name: "license",
  widget: "select",
  required: true,
  options: [{
    label: "All rights reserved",
    value: "all"
  }, {
    label: "CC Attribution - NonCommercial - NoDerrivatives",
    value: "cc-by-nc-nd"
  }, {
    label: "CC Attribution - NoDerrivatives",
    value: "cc-by-nd"
  }, {
    label: "CC Attribution - NonCommercial - ShareAlike",
    value: "cc-by-nc-sa"
  }, {
    label: "CC Attribution - NonCommercial",
    value: "cc-by-nc"
  }, {
    label: "CC Attribution - ShareAlike",
    value: "cc-by-sa"
  }, {
    label: "CC Attribution",
    value: "cc-by"
  }, {
    label: "CC Zero (Public Domain)",
    value: "cc-zero"
  }]
};
export const createSettingsFile = (opts) => {
  return {
    ...opts,
    file: `settings/${opts.name}.yaml`,
    fields: [
      {
        ...pageSlug,
        widget: "hidden",
        default: opts.name
      },
      ...opts.fields
    ]
  };
};
