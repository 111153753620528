import {headline, createMainFile} from "../fields/index.js";
const getCollection = async () => {
  return createMainFile({
    label: "Support",
    name: "support",
    fields: [{
      label: "Header",
      name: "head",
      widget: "object",
      fields: [
        headline,
        {
          label: "Contact Hook",
          name: "contactHook",
          widget: "string",
          required: true
        },
        {
          label: "Contact Text",
          name: "contactText",
          widget: "string",
          required: true
        }
      ]
    }]
  });
};
export default getCollection;
