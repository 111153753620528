import {pascalCase} from "../_snowpack/pkg/change-case.js";
import {blurbMark} from "../fields/index.js";
const types = ["bonus", "info", "warning", "error"];
const typeOpts = types.map((value) => ({
  value,
  label: pascalCase(value)
}));
export default function getHintEditor() {
  return {
    id: "hint",
    label: "Hint",
    fields: [
      {
        widget: "select",
        label: "Type",
        name: "type",
        options: typeOpts,
        required: true
      },
      {
        ...blurbMark,
        label: "Text",
        name: "text",
        required: true
      }
    ],
    pattern: /<aside class="hint (?<type>\w+)">\n{2}(?<text>.*?)\n{2}<\/aside>/su,
    fromBlock({groups}) {
      return groups;
    },
    toBlock({type, text = ""}) {
      return `<aside class="hint ${type}">

${text}

</aside>`;
    },
    toPreview({type, text = ""}) {
      return `<aside class="hint ${type}">

${text}

</aside>`;
    }
  };
}
