import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

import {coreFields, pageTitle} from "./core.js";
import {seoDescription, seoTitle} from "./seo.js";
export const getPreviewPath = (uri) => {
  return `api/preview?token=${__SNOWPACK_ENV__.ARC_PREVIEW_TOKEN}&slug=${uri}`;
};
export const isCollection = (x) => {
  return typeof x === "object" && !!x && x.hasOwnProperty("name");
};
export const parseCollections = async (...proms) => {
  return Promise.all(proms).then((res) => {
    return res.flat(Infinity).filter(isCollection);
  });
};
export const createFolder = (opts) => {
  return {
    format: "yaml-frontmatter",
    editor: {preview: false},
    slug: "{{fields.slug}}",
    sortable_fields: ["title", "publishDate"],
    create: true,
    delete: true,
    publish: true,
    hide: false,
    ...opts
  };
};
export const createFileFolder = (opts) => {
  return {
    format: "yaml-frontmatter",
    editor: {preview: false},
    slug: "{{slug}}",
    create: false,
    delete: false,
    publish: true,
    hide: false,
    ...opts
  };
};
export const isCollectionFile = (x) => {
  return typeof x === "object" && !!x && x.hasOwnProperty("name");
};
export const parseCollectionFiles = async (...proms) => {
  return Promise.all(proms).then((res) => {
    return res.flat(Infinity).filter(isCollectionFile);
  });
};
export const createMainFile = (opts) => {
  return {
    ...opts,
    file: `content/main/${opts.name}.md`,
    preview_path: getPreviewPath(opts.name === "home" ? "/" : `/${opts.name}`),
    fields: [
      ...coreFields,
      {name: "slug", widget: "hidden", default: opts.name},
      {...pageTitle, pattern: void 0},
      seoTitle,
      seoDescription,
      ...opts.fields
    ]
  };
};
