export const date = {
  label: "Date",
  name: "date",
  widget: "datetime",
  format: "YYYY-MM-DD",
  date_format: true,
  time_format: false,
  picker_utc: false,
  required: false
};
export const publishDate = {
  ...date,
  label: "Publish Date",
  name: "publishDate",
  hint: "You can use a future date.",
  required: false
};
export const createDate = {
  label: "Create Date",
  name: "createDate",
  widget: "hidden",
  required: false
};
export const updateDate = {
  label: "Update Date",
  name: "updateDate",
  widget: "hidden",
  required: false
};
