import {pageTitle, seoDescription, seoTitle, blurbMark, pageSlug, coreFields, getPreviewPath, createFolder} from "../fields/index.js";
const getCollection = async () => {
  return createFolder({
    name: "supportTag",
    label: "Support Tags",
    label_singular: "Tag",
    folder: "content/supportTag",
    preview_path: getPreviewPath("/support/tag/{{fields.slug}}"),
    sortable_fields: ["title", "order"],
    fields: [
      ...coreFields,
      {...pageTitle, pattern: void 0},
      pageSlug,
      seoTitle,
      seoDescription,
      {
        label: "Sort Order",
        hint: "Sorts the tag list. Tags only appear if they have content.",
        name: "order",
        widget: "number",
        value_type: "int",
        default: 1e4,
        step: 100,
        required: false
      },
      {
        ...blurbMark,
        name: "body",
        hint: "Show at the top of tag page. Short and sweet."
      }
    ]
  });
};
export default getCollection;
