export const seoTitle = {
  label: "SEO Title",
  name: "seoTitle",
  widget: "string",
  hint: "Between 45-60 characters long. Only required for long titles.",
  pattern: ["^.{45,60}$", "Between 45 and 60 characters"],
  required: false
};
export const seoDescription = {
  label: "SEO Description",
  name: "seoDescription",
  widget: "text",
  hint: "Between 110-160 characters long. Seductive to search traffic.",
  pattern: ["^.{110,160}$", "Between 110 and 160 characters"],
  required: false
};
