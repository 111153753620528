import {pageTitle, seoDescription, seoTitle, photoImage, pageSlug, blurbMark, linkEx, coreFields, getPreviewPath, createFolder} from "../fields/index.js";
const getCollection = async () => {
  return createFolder({
    name: "person",
    label: "People",
    label_singular: "Person",
    folder: "content/person",
    preview_path: getPreviewPath("/blog/author/{{fields.slug}}"),
    fields: [
      ...coreFields,
      {...pageTitle, label: "Name", pattern: void 0},
      pageSlug,
      seoTitle,
      seoDescription,
      {...photoImage, required: true},
      {
        label: "Role",
        name: "role",
        widget: "string",
        required: false
      },
      {
        label: "Email",
        name: "email",
        widget: "string",
        required: false
      },
      {
        ...linkEx,
        label: "Website",
        name: "website",
        required: false
      },
      {
        ...blurbMark,
        label: "Bio",
        name: "body",
        required: true
      }
    ]
  });
};
export default getCollection;
