export const GITHUB_DATABASE_BRANCH = "master";
export const APP_SITE_URL = "https://www.sitearcade.com";
export const APP_LOCAL_URL = "https://cms.sitearcade.com";
export const PROJECT_CWD = "/vercel/path0";
export const GITHUB_ACCESS_TOKEN = "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";
export const INIT_CWD = "/vercel/path0/apps/hub-cms";
export const VERCEL_GIT_COMMIT_REF = "master";
export const GITHUB_OAUTH_CLIENT_ID = "87f1c25d375cac296a6e";
export const ENABLE_ROOT_PATH_BUILD_CACHE = "1";
export const NODE_OPTIONS = "--max_old_space_size=8192";
export const VERCEL_BUILD_OUTPUTS_EDGE_FUNCTION = "1";
export const CLOUDINARY_API_SECRET = "qsJgWB-5bF5iRtH8nk3CKXcI6tY";
export const GITHUB_DATABASE_REPO = "sitearcade/hub-database";
export const VERCEL_EDGE_FUNCTIONS_STRICT_MODE = "1";
export const VERCEL_GIT_COMMIT_SHA = "cef9bd314f88db34bd13f19a3ccf0b7440038c73";
export const YARN_NODE_LINKER = "node-modules";
export const LD_LIBRARY_PATH = "/lib64:/usr/lib64";
export const NEXT_PRIVATE_MULTI_PAYLOAD = "1";
export const VERCEL_ARTIFACTS_TOKEN = "artifacts:eyJkZXBsb3ltZW50SWQiOiJkcGxfNTNSY2NWTjV1QVVzQThhSkdheTdhbUV0THhVQiIsIm93bmVySWQiOiJ0ZWFtX3lnQmJXcmJvcm5YcDhlWWh5V2dkcURwWiIsInRpY2tldCI6ImdxRjB6UWNJb1dPUzJTQmtjR3hmTlROU1kyTldUalYxUVZWelFUaGhTa2RoZVRkaGJVVjBUSGhWUXExQlVFbGZRVkpVU1VaQlExUlR0elhMT2tqZzVkYk9CbVJwSm5xOVNiYzFKY289In0=";
export const npm_execpath = "/tmp/xfs-407e006e/yarn";
export const FAKEROOTKEY = "yes";
export const NEXT_SHARP_PATH = "/usr/local/share/.config/yarn/global/node_modules/sharp";
export const AWS_EXECUTION_ENV = "AWS_ECS_FARGATE";
export const FLUENT_HOST = "127.0.0.1";
export const MAILERLITE_API_KEY = "c138fcfea59df531dd228b697856fc5c";
export const VERCEL_ARTIFACTS_OWNER = "team_ygBbWrbornXp8eYhyWgdqDpZ";
export const PATH = "/tmp/xfs-407e006e:/tmp/xfs-5221bf71:/tmp/xfs-93ed7775:/vercel/path0/node_modules/.bin:/vercel/path0/apps/hub-cms/vendor/bin:/vercel/.yarn/bin:/vercel/.config/yarn/global/node_modules/.bin:/node16/bin:/ruby27/bin:/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin";
export const USE_OUTPUT_FOR_EDGE_FUNCTIONS = "1";
export const NOW_BUILDER = "1";
export const PWD = "/vercel/path0/apps/hub-cms";
export const npm_lifecycle_event = "build";
export const npm_package_name = "@arc/hub-cms";
export const LANG = "en_US.UTF-8";
export const TZ = ":UTC";
export const ARC_PREVIEW_TOKEN = "domoarigatomisterroboto";
export const YARN_IGNORE_CWD = "1";
export const VERCEL = "1";
export const npm_package_version = "";
export const GITHUB_OAUTH_CLIENT_SECRET = "1b075f679e0d5901b18a528a4f3a09f30efac209";
export const HOME = "/vercel";
export const SHLVL = "2";
export const MAILERLITE_GROUP_DEFAULT = "104538811";
export const FLUENT_PORT = "24224";
export const NX_DAEMON = "false";
export const BERRY_BIN_FOLDER = "/tmp/xfs-407e006e";
export const npm_config_user_agent = "yarn/3.2.0 npm/? node/v16.16.0 linux x64";
export const CLOUDINARY_CLOUD_NAME = "sitearcade";
export const VERCEL_ENV = "production";
export const ENABLE_VC_BUILD = "1";
export const GITHUB_DATABASE_CACHE = "/tmp/database";
export const CLOUDINARY_API_KEY = "164341212258172";
export const VERCEL_PROJECT_ID = "QmepmDm11RaaVBQT7AdL3e6xtZQy2GCZYi311EpcRdHeN1";
export const __VERCEL_BUILD_RUNNING = "1";
export const VERCEL_ORG_ID = "team_ygBbWrbornXp8eYhyWgdqDpZ";
export const DD_TRACE_STARTUP_LOGS = "false";
export const npm_node_execpath = "/tmp/xfs-407e006e/node";
export const NODE_ENV = "production";
export const MODE = "production";
export const SSR = false;