import {pageSlug, pageTitle, publishDate, seoDescription, seoTitle, body, supportTags, coreFields, getPreviewPath, createFolder} from "../fields/index.js";
const getCollection = async () => {
  return createFolder({
    name: "support",
    label: "Support Articles",
    label_singular: "Support Article",
    folder: "content/support",
    slug: "{{slug}}",
    create: true,
    preview_path: getPreviewPath("/support/article/{{fields.slug}}"),
    editor: {preview: false},
    fields: [
      ...coreFields,
      pageTitle,
      pageSlug,
      publishDate,
      seoTitle,
      seoDescription,
      supportTags,
      body
    ]
  });
};
export default getCollection;
